import React, {useContext} from 'react';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import SEO from '../components/seo';
import classes from './page-title.module.css';

interface PageTitleProps {
  title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({title}) => {
  const {theme} = useContext(GlobalStateContext);
  return (
    <>
      <SEO title={title} />
      <h1 className={clsx(classes.title, theme == 'regular' ? classes.underline : classes.title_black_and_white)}>
        {title}
      </h1>
    </>
  );
};
export default PageTitle;
