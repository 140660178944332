import React, {useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {navigate} from 'gatsby';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import ReCaptchaV2 from 'react-google-recaptcha';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import TextInput from '../components/text-input';
import FileInput from '../components/file-input';
import CheckboxInput from '../components/checkbox-input';
import SubmitButton from '../components/submit-button';
import classes from './contacts.module.css';

interface ImportedFile {
  name: string;
  file: string | ArrayBuffer | null;
}

const OnlineForm: React.FC = () => {

  return (
    <Layout>
      <Container>
        <PageTitle title="Контакти" />

        <section className={classes.contacts}>
          <p>Адреса: 46007 м. Тернопіль, просп. Злуки, 2В</p>
          <p>Електронна пошта: kanc@tepo.com.ua</p>
          <p>Графік роботи: 8:00-17:12 (понеділок-четвер), 8:00-16:12 (п’ятниця).</p>
          <p>Обідня перерва: 13:00-14:00.</p>
          <p>Графік прийому (директором): 10:00-12:00 (вівторок, четвер).</p>
          <p>Тел./Факс: (0352) 52-30-09</p>
        </section>
      </Container>
    </Layout>
  );
};

export default OnlineForm;
